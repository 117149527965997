@media (min-width: 960px) {
  .section--main-page {
    background-position: right bottom;
    background-repeat: no-repeat;
    border-radius: 40px;

    &-ru {
      background-image: url(/img/main-page-hero-ru.png);
      background-size: auto;
    }

    &-es {
      background-image: url(/img/main-page-hero-es.png);
      background-size: contain;
    }
  }
}

.user-form {
  max-width: 420px !important;

  h1 {
    margin-left: -5px;
    margin-right: -5px;
  }
}

.white-divider {
  height: 0.625rem;
  background-color: #fff;
  box-shadow: -200px 0 #fff, 200px 0 #fff;
}