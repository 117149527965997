.footer {
  background-color: #fff;
  box-shadow: 8px 0px 15px rgba(110, 120, 130, 0.12);

  nav {
    a {
      color: #6E7882;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--dark {
    background-color: #0E1E0E;
  }
}