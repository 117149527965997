// @use "sass:math";

$base-font-size-ec: 16px !default;
$browser-context: 16;
$vw-viewport: 320;

$vs-dropdown-max-height: 200px !default;

/*
@mixin pc() {
  @media all and (min-width: 760px) {
    @content;
  }
}

@mixin mobile() {
  @media all and (max-width: 759px) {
    @content;
  }
}

@mixin bigPc() {
  @media all and (min-width: 1650px) {
    @content;
  }
}

// px to vw
@function vw($pxval){
  $width: $pxval * 1px;

  $vw-context: $vw-viewport * 0.01 * 1px;
  @return math.div($width, $vw-context) * 1vw;
  @return $width;
}

// Delete px/percent... values
@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

// px to rem
@function rem($pxval) {
  $base: $base-font-size-ec;

  @if not unitless($base) {
    $base: strip-unit($base);
  }

  @return math.div($pxval, $base) * 1rem;
}

html {
  @include bigPc {
    font-size: calc(16px + (18 - 14) * ((100vw - 1650px) / (2560 - 1650)));
  }

  @include mobile {
    font-size: calc(16px + (30 - 14) * ((100vw - 320px) / (760 - 320)));
  }
}
*/