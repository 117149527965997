:root {
  --color-main-hue: 24;
  --color-main-saturation: 100%;
  --color-main-lightness: 49%;
  --color-main-lightness-hover: 52%;
}

html {
  min-height: 100vh;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  & {
    scrollbar-color: hsl(
      var(--color-main-hue),
      var(--color-main-saturation),
      var(--color-main-lightness)
    ) #F9FAFB;
    scrollbar-width: thin;

    * {
      &::-webkit-scrollbar {
        width: 6px;
        height: 5px;
        appearance: none;
      }

      &::-webkit-scrollbar-track {
        background-color: #F9FAFB;
        border-radius: 3px;
        border: 1px solid #F9FAFB;
      }

      &::-webkit-scrollbar-thumb {
        background-color: hsl(
                        var(--color-main-hue),
                        var(--color-main-saturation),
                        var(--color-main-lightness)
        );
        border-radius: 3px;
        box-shadow: none;
      }
    }
  }
}

body {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

.main {
  display: flex;
}

.page {
  flex: 1 0 auto;
}

*:focus-visible {
  outline: none;
}

.gc-qr-scanner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px;
}

.gc-qr-scanner #qrScannerImgBtn {
  max-width: 200px;
}
.gc-qr-scanner #checkcertificateform-code {
  padding-right: 75px;
}

.gc-qr-scanner__viewport {
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.gc-input__action-icon {
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px;
  box-shadow: -17px 0px 13px 1px #fff;
  cursor: pointer;
}

.gc-input__action-icon:hover {
  background-color: rgb(var(--gc-button-color-primary));
}

.gc-input__action-icon:hover svg path {
  fill: var(--gc-button-text-color);
}

img, video {
  display: inline-block;
  max-width: 100%;
}


#qrScannerImgBtn.gc-input__action-icon ~ .gc-qr-scanner__viewport  > #qrScannerImg,
#qrScannerVideoBtn.gc-input__action-icon ~ .gc-qr-scanner__viewport  > #qrScannerVideo {
  display: block;
}

#qrScannerVideoBtn.gc-input__action-icon ~ .gc-qr-scanner__viewport  > #qrScannerVideoBtnCancel {
  display: flex;
}

#qrScannerCanvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.gc-qr-scanner__btn--close {
  width: 40px;
  height: 40px;
  background-color: rgb(0,0,0,0.85);
  color: #fff;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
}
